import firebase from 'firebase/app';

export interface Store {
    createdAt?: firebase.firestore.Timestamp;
    updatedAt?: firebase.firestore.Timestamp | null;
    token?: string;
    id?: string;
    merchantKey?: string;
    merchantSecret?: string;
    merchantApiKey?: string;
    testMerchantKey?: string;
    testMerchantSecret?: string;
    testMerchantApiKey?: string;
    merchantSettings?: Record<string, unknown>;
    keys?: Record<
        string,
        Record<
            string,
            {
                merchantKey: string;
                merchantSecret: string;
                merchantApiKey: string;
                testMerchantKey: string;
                testMerchantSecret: string;
                testMerchantApiKey: string;
            }
        >
    >;
}

export interface Shop {
    shop: string;
}

// Not all items might not be present; see (project-root)/functions/src/firestore/interfaces.ts:PaymentStatus for full
// enumeration and update, if needed
export enum PaymentStatus {
    /**
     * Payment creation in the payment service provider API failed and returned an error
     */
    ERROR = 'error',
    /**
     * Payment is created, but it is somehow in invalid state or has invalid data
     */
    INVALID = 'invalid',
    /**
     * Pending; new payment is created, but waiting for buyer to further the operation
     */
    PENDING = 'pending',
    /**
     * Processing the payment has begun
     */
    PROCESSING = 'processing',
    /**
     * Processing was successful. Waiting to complete the payment
     */
    SUCCESS = 'success',
    /**
     * Payment is processed and completed
     */
    COMPLETE = 'complete',
    /**
     * Payment is processed, but was canceled by the buyer or the payment processor
     */
    CANCELED = 'canceled',
    /**
     * Payment is processed, but was rejected by the payment processor
     */
    REJECTED = 'rejected',
    /**
     * Payment or refund was created, but could not (yet) be fulfilled at the time it was tried (like card payment not
     * yet captured to have refundable balance available)
     */
    UNFULFILLABLE = 'unfulfillable',
    /**
     * Payment was previously processed successfully but later refunded
     */
    REFUNDED = 'refunded',
    /**
     * Payment is a repeating payment and has been authorized
     *
     * TODO: are the authorized payment statuses needed in the app?
     */
    AUTHORIZED = 'authorized',
    /**
     * Authorized payment has been captured at least once
     */
    CAPTURED = 'captured',
    /**
     * Authorized payment has been voided
     */
    VOID = 'void',
}

export const enum ItemTypes {
    PAYMENT = 'payment',
    REFUND = 'refund',
}

export interface Payment extends Record<string, any> {
    id: string;
    status: PaymentStatus;
    shopify: {
        requestCount: number;
        graphqlId: string;
        cancelUrl: string;
        successUrl?: string;
        rejectUrl?: string;
        paymentRequest: {
            id: string;
            gid: string;
            group: string;
            amount: string;
            currency: string;
            test: boolean;
            merchant_locale: string;
            payment_method: {
                type: string;
                data: {
                    cancel_url: string;
                };
            };
            proposed_at: string;
            customer: {
                billing_address: {
                    given_name: string;
                    family_name: string;
                    line1: string;
                    line2: string;
                    city: string;
                    postal_code: string;
                    province: string;
                    country_code: string;
                    company: string;
                };
                shipping_address?: {
                    given_name: string;
                    family_name: string;
                    line1: string;
                    line2: string;
                    city: string;
                    postal_code: string;
                    province: string;
                    country_code: string;
                    company: string;
                };
                email: string;
                phone_number: string;
                locale: string;
            };
            kind: string;
        };
        /**
         * reference to the {@link Refund.id}
         */
        refunds?: { refundId: string; amount: number }[];
    };
    paymentProcessor: {
        redirectUrl: string;
        transactionId: string;
        status?: { level: string; timestamp: string };
        paymentId?: string;
        orderId?: string;
    };
    test: boolean;
    /**
     * Payment provider app ID that was used to initiate the payment
     */
    provider: string;
    createdAt: firebase.firestore.Timestamp;
    updatedAt: firebase.firestore.Timestamp | null;
    completedAt: firebase.firestore.Timestamp | null;
}

export interface Refund extends Record<string, any> {
    id: string;
    status: PaymentStatus;
    shopify: {
        requestCount: number;
        graphqlId: string;
        /**
         * reference to the {@link Payment.id}
         */
        paymentId: string;
        refundRequest: {
            id: string;
            gid: string;
            payment_id: string;
            amount: string;
            currency: string;
            merchant_locale: string;
            proposed_at: string;
        };
    };
    paymentProcessor: {
        /**
         * Payment processor's internal ID for the refund
         */
        refundId: string;
        transactionId: string;
        paymentId: string;
        orderId: string;
    };
    test: boolean;
    /**
     * Payment provider app ID that was used to initiate the refund. Should match the value in the Payment#provider
     */
    provider: string;
    createdAt: firebase.firestore.Timestamp;
    updatedAt: firebase.firestore.Timestamp | null;
    completedAt: firebase.firestore.Timestamp | null;
}
