import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, DisplayText } from '@shopify/polaris';
import { getQueryParams } from '../utils';
import { install } from '../api/auth';

const Install = () => {
    const { provider } = useParams<{ provider?: string }>();

    useEffect(() => {
        const queryParams = getQueryParams();
        if (provider) {
            queryParams['provider'] = provider;
            console.debug(`Install path had provider ID '${provider}'`);
        }
        const initInstall = async () => {
            try {
                const data = await install(queryParams);
                const url = `/${provider ? `${provider}` : 'default'}${window.location.search}&token=${data.token}`;
                console.debug(`Install call finished; redirecting to dashboard (${url})`);
                window.location.href = url;
            } catch (e) {
                console.log('install error: ', e);
            }
        };

        initInstall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Spinner accessibilityLabel="Loading app" size="large" />
            <div style={{ marginLeft: '5px' }}>
                <DisplayText>Installing</DisplayText>
            </div>
        </div>
    );
};

export default Install;
