import {
    Button,
    Divider,
    Select as PolarisSelect,
    SettingToggle as PolarisSettingToggle,
    Stack,
    TextField,
    TextStyle,
    Card,
} from '@shopify/polaris';
import React, { ReactElement, ReactNode, ReactText, useEffect, useState } from 'react';
import { InitialValueEnv } from '../components/Settings';
import { config, currencyOptions, Environment, Option } from '../config';

const { requiredFields, keySpecificRequiredFields, additionalRequiredFields } = config;

/**
 * Copy-paste declaration from polaris module's TextField.d.ts
 */
export type Type =
    | 'text'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | 'date'
    | 'datetime-local'
    | 'month'
    | 'time'
    | 'week'
    | 'currency';

export type FieldType = Type | 'textfield' | 'toggle-button' | 'select';

type FieldDisabled = {
    /**
     * One of the following:
     * * Reference to a component in AdditionalFormData,
     * * `!value` to skip component reference check and simply use the `value` boolean
     * * `!self` to target self (TODO)
     */
    valueRef: '!value' | '!self' | string;
    value: boolean;
    label: string;
};

export interface RequiredField {
    label: string;
    helpText?: ((env: InitialValueEnv) => string | ReactText | ReactElement) | string | ReactNode | ReactElement;
    id: string;
    type: string;
    test: boolean;
    disable?: FieldDisabled;
    attributes?: {
        maxLength?: number;
    };
    valueControlRegExp?: RegExp;
    environment?: Environment;
}

export interface FormData extends Record<string, string> {
    id: string;
    key: string;
    merchantApiKey: string;
    testId: string;
    testKey: string;
    testMerchantApiKey: string;
    // currency: string;
}

export interface AdditionalFormData extends Record<string, any> {
    termsUri?: string;
    transactionByTransactionSettlement?: boolean;
    idPrefix?: string;
    currency?: string;
    multiCurrencySupport?: boolean;
    merchantLogo?: string;
    hideAnonymousFlag?: boolean
    hideNotYouFlag?: boolean
    hideChangeAddressFlag?: boolean
}

export type MulticurrencySpecificFormData = Record<
    string,
    {
        from: string;
        rates: { [symbol: string]: string | number };
        date: number;
    }
>;

// TODO: add typed returns for the config/form functions. never[] or any[] isn't quite nice down the line...

export const getConfig = ({
    formData,
    setFormData,
    requiredFields,
}: {
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
    requiredFields: RequiredField[];
}) => {
    return requiredFields.map((field: RequiredField) => ({
        ...field,
        value: formData[field.id],
        setState: (newValue: string) => setFormData({ ...formData, [field.id]: newValue }),
    }));
};

export const getKeySpecificConfig = ({
    formData,
    setFormData,
    requiredFields,
}: {
    formData: Record<string, Record<string, FormData>>;
    setFormData: React.Dispatch<React.SetStateAction<Record<string, Record<string, FormData>>>>;
    requiredFields: Record<string, Record<string, RequiredField[]>>;
}) => {
    return Object.entries(requiredFields).reduce((acc, [key, settingField]) => {
        const settingFields = Object.entries(settingField).reduce((acc, [keySpecificKey, fields]) => {
            return {
                ...acc,
                [keySpecificKey]: fields.map((field) => {
                    // FIXME: this is somehow really flaky and hard to grasp with all of the spreads and reduces (of
                    //  which each of them copies and reassigns the whole objects references in hidden loops...).
                    //  Also, missing props in document and an unexpected error because of typo'd "testMerchanSecret"
                    //  property in Settings.tsx brought the whole UI construction down
                    return {
                        ...field,
                        value: formData[key]?.[keySpecificKey]?.[field.id] || '',
                        setState: (newValue: string) =>
                            setFormData({
                                ...formData,
                                [key]: {
                                    ...(formData[key] || {}),
                                    [keySpecificKey]: {
                                        ...(formData[key]?.[keySpecificKey] || {}),
                                        [field.id]: newValue,
                                    },
                                },
                            }),
                    };
                }),
            };
        }, {});

        return { ...acc, [key]: settingFields };
    }, []);
};

export const getAdditionalConfig = (
    additionalFormData: AdditionalFormData,
    setAdditionalFormData: React.Dispatch<React.SetStateAction<AdditionalFormData>>,
    additionalRequiredFields: RequiredField[],
) => {
    return additionalRequiredFields.map((field: RequiredField) => ({
        ...field,
        value: additionalFormData[field.id],
        setState:
            field.type === 'toggle-button'
                ? () =>
                      setAdditionalFormData({
                          ...additionalFormData,
                          [field.id]: !additionalFormData[field.id],
                      })
                : (newValue?: string) =>
                      setAdditionalFormData({
                          ...additionalFormData,
                          [field.id]: newValue,
                      }),
    }));
};

const PasswordField = ({
    label,
    id,
    value,
    onChange,
}: {
    label: string;
    id: string;
    value: string;
    onChange: (newValue: string) => void;
}) => {
    const [passwordToggle, setPasswordToggle] = useState<boolean>(false);
    // const [hasBeenBlurred, sethasBeenBlurred] = useState<boolean>(false);
    return (
        <div style={{ position: 'relative' }}>
            <TextField
                autoComplete="off"
                // autoFocus
                label={label}
                id={id}
                value={value}
                onChange={onChange}
                type={passwordToggle ? 'text' : 'password'}
                // error={hasBeenBlurred && !value && `${label} is required`}
                // onBlur={() => sethasBeenBlurred(true)}
            />
            <div
                style={{
                    position: 'absolute',
                    zIndex: 100,
                    right: '10px',
                    top: '32px',
                }}
            >
                <Button onClick={() => setPasswordToggle(!passwordToggle)} plain>
                    {passwordToggle ? 'Hide' : 'Show'}
                </Button>
            </div>
        </div>
    );
};

const InputField = ({
    label,
    helpText = '',
    id,
    value,
    type,
    onChange,
    isDisabled = false,
    disableLabel,
    attributes = {},
    valueControlRegExp,
}: {
    label: string;
    helpText?: string | ReactNode | ReactElement;
    id: string;
    value: string;
    type: Type;
    onChange: (newValue: string) => void;
    isDisabled?: boolean;
    disableLabel?: string;
    attributes?: { maxLength?: number } | {};
    valueControlRegExp?: RegExp;
}) => {
    // const [hasBeenBlurred, setHasBeenBlurred] = useState<boolean>(false);

    useEffect(() => {
        if (isDisabled) onChange('');
    }, [onChange, isDisabled]);

    return (
        <TextField
            autoComplete="off"
            // autoFocus
            label={label}
            helpText={isDisabled && disableLabel ? disableLabel : helpText}
            id={id}
            value={value}
            onChange={(val) => {
                if (valueControlRegExp) return onChange(val.replace(valueControlRegExp, ''));
                return onChange(val);
            }}
            type={type}
            disabled={isDisabled}
            {...attributes}
            // error={hasBeenBlurred && !value && `${label} is required`}
            // onBlur={() => sethasBeenBlurred(true)}
        />
    );
};

const Select = (props: {
    label: string;
    id: string;
    options: Option[];
    value: string;
    onChange: (newValue: string) => void;
}) => {
    return <PolarisSelect {...props} />;
};

const SettingToggle = ({
    _label,
    _id,
    value,
    _type,
    helpText = '',
    isDisabled = false,
    onChange,
}: {
    _label: string;
    _id: string;
    value: boolean;
    _type: FieldType;
    helpText: string;
    isDisabled: boolean;
    onChange: () => void;
}) => {
    return (
        <PolarisSettingToggle
            action={{
                disabled: isDisabled,
                content: value ? 'Disable' : 'Enable',
                onAction: onChange,
            }}
            enabled={value}
        >
            <Stack>
                <div>
                    This setting is <TextStyle variation="strong">{value ? 'enabled' : 'disabled'}</TextStyle>.
                </div>
                ${value && helpText ? <div>{helpText}</div> : ''}
            </Stack>
        </PolarisSettingToggle>
    );
};

export const useFormConfig = (formData: FormData, setFormData: React.Dispatch<React.SetStateAction<FormData>>) => {
    const formContent = getConfig({ formData, setFormData, requiredFields });
    const formMarkup = formContent.map((field) => {
        if (field.type === 'password')
            return (
                <PasswordField
                    key={field.id}
                    label={field.label}
                    id={field.id}
                    value={field.value}
                    onChange={field.setState}
                />
            );

        return (
            <InputField
                key={field.id}
                label={field.label}
                id={field.id}
                value={field.value}
                type={field.type as Type}
                onChange={field.setState}
            />
        );
    });

    return formMarkup;
};

export const useKeySpecificFormConfig = (
    formData: Record<string, Record<string, FormData>>,
    setFormData: React.Dispatch<React.SetStateAction<Record<string, Record<string, FormData>>>>,
) => {
    const formContent = getKeySpecificConfig({
        formData,
        setFormData,
        requiredFields: keySpecificRequiredFields,
    });

    return Object.values(formContent).flatMap((settings) => {
        const overwrites = Object.values(settings).flatMap((fields: any) => {
            // FIXME: no any
            return fields.flatMap((field: any) => {
                if (field.type === 'password')
                    return (
                        <PasswordField
                            key={field.id}
                            label={field.label}
                            id={field.id}
                            value={field.value}
                            onChange={field.setState}
                        />
                    );

                return (
                    <InputField
                        key={field.id}
                        label={field.label}
                        id={field.id}
                        value={field.value}
                        type={field.type as Type}
                        onChange={field.setState}
                    />
                );
            });
        });
        return [<Divider />].concat(overwrites); // FIXME: divider between all "groups" of per-currency credentials
    });
};

export const useMulticurrencySpecificFormConfig = (
    formData: MulticurrencySpecificFormData,
    setFormData: React.Dispatch<React.SetStateAction<MulticurrencySpecificFormData>>,
) => {
    return Object.values(formData).map((m) => (
        <Card sectioned>
            <TextStyle variation="strong">{m.from} Conversions</TextStyle>
            {Object.entries(m.rates).map(([to, rate]) => (
                <TextField
                    autoComplete="off"
                    key={to}
                    label={``}
                    id={to}
                    value={rate as string}
                    type={'number'}
                    prefix={`1 ${m.from} =`}
                    suffix={to}
                    min={0}
                    onChange={(newValue: string) => {
                        setFormData({
                            ...formData,
                            [m.from]: {
                                ...formData[m.from],
                                rates: { ...formData[m.from].rates, [to]: newValue },
                            },
                        });
                    }}
                />
            ))}
        </Card>
    ));
};

const deriveIsDisabled = (disable: FieldDisabled | undefined, additionalFormData: AdditionalFormData): boolean =>
    !!disable && (disable.valueRef === '!value' || additionalFormData[disable.valueRef] === disable.value);

export const useAdditionalFormConfig = (
    additionalFormData: AdditionalFormData,
    setAdditionalFormData: React.Dispatch<React.SetStateAction<AdditionalFormData>>,
    initialValueEnv: InitialValueEnv,
) => {
    const additionalFormContent = getAdditionalConfig(
        additionalFormData,
        setAdditionalFormData,
        additionalRequiredFields as RequiredField[],
    );

    const additionalFormMarkup = additionalFormContent.map((field) => {
        switch (field.type) {
            case 'toggle-button':
                return (
                    <SettingToggle
                        key={field.id}
                        _label={field.label}
                        _id={field.id}
                        value={field.value}
                        _type={field.type}
                        helpText={
                            typeof field.helpText === 'function' ? field.helpText(initialValueEnv) : field.helpText
                        }
                        onChange={field.setState}
                        isDisabled={deriveIsDisabled(field.disable, additionalFormData)}
                    />
                );
            case 'password':
                return (
                    <PasswordField
                        key={field.id}
                        label={field.label}
                        id={field.id}
                        value={field.value}
                        onChange={field.setState}
                    />
                );
            case 'select':
                return (
                    <Select
                        key={field.id}
                        label={field.label}
                        options={currencyOptions}
                        id={field.id}
                        value={field.value}
                        onChange={field.setState}
                    />
                );
        }
        return (
            <InputField
                key={field.id}
                label={field.label}
                helpText={
                    typeof field.helpText === 'function'
                        ? field.helpText({
                              ...initialValueEnv,
                              ...(field.id === 'merchantLogo' && { merchantLogo: field.value }),
                          })
                        : field.helpText
                }
                id={field.id}
                value={field.value}
                type={
                    // IMPL NOTE: this cast is compile-type only. Since the field type could be a value not covered by
                    // the Type declaration as not all special types are handled in the switch statement above. The
                    // behaviour is undefined, as the type gets passed in as string as-is when the React input component
                    // is created
                    field.type as Type
                }
                onChange={field.setState}
                isDisabled={deriveIsDisabled(field.disable, additionalFormData)}
                disableLabel={field.disable?.label}
                attributes={field.attributes}
                valueControlRegExp={field.valueControlRegExp}
            />
        );
    });

    return { additionalFormMarkup };
};
