import { firestore } from '../utils/firebase';
// import firebase from 'firebase/app';
// import { Offer, Estimate, ShippingDocument, Quote } from '../interfaces/offer';
// import { Message } from '../interfaces/message';
import { Store, Payment, Refund } from '../interfaces';

export const getMerchant = async (shop: string, providerId?: string): Promise<Store> => {
    // const provider = providerId || 'default';
    const res = await firestore.collection('stores').doc(shop).get();

    if (!res.exists)
        return {
            merchantKey: '',
            merchantSecret: '',
            merchantApiKey: '',
            testMerchantKey: '',
            testMerchantSecret: '',
            testMerchantApiKey: '',
            // currency: '',
        };

    return { ...res.data() };
};

export const getPayments = async (shop: string) => {
    console.log('fetching payments for shop', shop);
    const query = firestore.collection(shop).doc('payments').collection('payments');
    // .where('status', '==', 'active');
    const result = await query.orderBy('createdAt', 'asc').get();
    if (result.empty) return [];
    return result.docs.map((doc) => ({
        key: doc.ref.id,
        ...(doc.data() as Payment),
    }));
};

export const getRefunds = async (shop: string) => {
    const query = firestore.collection(shop).doc('refunds').collection('refunds');
    // .where('status', '==', 'active');
    const result = await query.orderBy('createdAt', 'asc').get();
    if (result.empty) return [];
    return result.docs.map((doc) => ({
        key: doc.ref.id,
        ...(doc.data() as Refund),
    }));
};
