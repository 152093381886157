import { AppProvider, Frame, Navigation, Spinner } from '@shopify/polaris';
import { PaymentsMajor, RefundMajor, SettingsMajor } from '@shopify/polaris-icons';
import enTranslations from '@shopify/polaris/locales/en.json';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { authenticate } from './api/auth';
import ErrorPage from './components/ErrorPage';
import FirebaseProvider from './context/FirebaseContext';
import ShopProvider from './context/ShopContext';
import Routes from './routes/routes';
import { ApiError, getPath, getQueryParams, QueryParams } from './utils';

const App = () => {
    const location = useLocation();
    let path = getPath().slice(1);
    const provider = path[path.length - 1];
    const queryParams: QueryParams = getQueryParams();
    if (provider) {
        queryParams['provider'] = provider;
    }
    const { shop: shopParam, code } = queryParams;
    const tokenParam = queryParams['token'];
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState('');
    const [shopUrl, setShopUrl] = useState('');
    const [error, setError] = useState<ApiError | SyntaxError | DOMException | null>(null);

    const setShopData = (token: string, shop: string) => {
        setToken(token);
        setShopUrl(shop);
    };

    const auth = useCallback(async () => {
        let hasAuthUrl = false;
        try {
            const data = await authenticate(queryParams);
            if (data.authUrl) {
                hasAuthUrl = true;
                window.location.href = data.authUrl;
            }
            setShopData(data.token, data.shop);
        } catch (e) {
            if (e instanceof TypeError) {
                // For reasons unknown, the fetch API rejects with TypeError in case of network errors
                console.error(`Networking issues when making the request (most likely)`, e);
                setError(e);
            } else if (e instanceof SyntaxError) {
                console.error(`Error parsing server response`, e);
                setError(e);
            } else if (e instanceof ApiError) {
                console.error(`Server responded with status ${e.message}${e.payload}`, e);
                setError(e);
            } else {
                console.error(e);
            }
        } finally {
            if (!hasAuthUrl) setLoading(false);
        }
    }, [queryParams]);

    useEffect(() => {
        if (shopParam && tokenParam) {
            // If store domain and a JWT token passed in as query parameters, use them
            setShopData(tokenParam, shopParam);
            setLoading(false);
        } else if (!code && !error) {
            auth();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopParam, tokenParam, code]);

    const toggleMobileNavigationActive = () => setMobileNavigationActive(!mobileNavigationActive);
    const navigation = (
        <>
            <Navigation location="/">
                <Navigation.Section
                    items={[
                        {
                            url: '/' + provider + window.location.search,
                            label: 'Settings',
                            icon: SettingsMajor,
                            selected: location.pathname === '/' + provider,
                        },
                        {
                            url: '/payments/' + provider + window.location.search,
                            label: 'Payments',
                            icon: PaymentsMajor,
                            selected: location.pathname.startsWith('/payments'),
                        },
                        {
                            url: '/refunds/' + provider + window.location.search,
                            label: 'Refunds',
                            icon: RefundMajor,
                            selected: location.pathname.startsWith('/refunds'),
                        },
                    ]}
                />
            </Navigation>
        </>
    );
    return (
        <AppProvider
            i18n={enTranslations}
            linkComponent={(props: any) => (
                <Link className={props.className} to={props.url || ''}>
                    {props.children}
                </Link>
            )}
        >
            <FirebaseProvider token={token}>
                <ShopProvider shopUrl={shopUrl}>
                    <>
                        <Frame
                            navigation={navigation}
                            showMobileNavigation={mobileNavigationActive}
                            onNavigationDismiss={toggleMobileNavigationActive}
                        >
                            {loading ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        height: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Spinner accessibilityLabel="Loading app" size="large" />
                                </div>
                            ) : error ? (
                                <ErrorPage error={error} />
                            ) : (
                                <Routes />
                            )}
                        </Frame>
                    </>
                </ShopProvider>
            </FirebaseProvider>
        </AppProvider>
    );
};

export default App;
