import firebase from 'firebase';

let app: firebase.app.App;
let auth: firebase.auth.Auth;
let firestore: firebase.firestore.Firestore;

const {
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIREBASE_AUTH_DOMAIN,
    REACT_APP_FIREBASE_DATABASE_URL,
    REACT_APP_FIREBASE_PROJECT_ID,
    REACT_APP_FIREBASE_STORAGE_BUCKET,
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    REACT_APP_FIREBASE_APP_ID,
    REACT_APP_FIRESTORE_EMULATOR_HOST,
    REACT_APP_FIREBASE_AUTH_EMULATOR_HOST,
} = process.env;

try {
    //check if firebase app is already initialized; initialize if not, otherwise use existing app
    app = !firebase.apps.length
        ? firebase.initializeApp({
              apiKey: REACT_APP_FIREBASE_API_KEY,
              authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
              databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
              projectId: REACT_APP_FIREBASE_PROJECT_ID,
              storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
              messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
              appId: REACT_APP_FIREBASE_APP_ID,
          })
        : firebase.app();

    auth = firebase.auth();
    if (REACT_APP_FIREBASE_AUTH_EMULATOR_HOST) {
        const [host, port] = REACT_APP_FIREBASE_AUTH_EMULATOR_HOST.split(':');
        const url = `http://${host}:${port}`;
        auth.useEmulator(url);
        console.debug(`Found REACT_APP_FIREBASE_AUTH_EMULATOR_HOST, using emulator for Firestore at ${url}`);
    }

    firestore = firebase.firestore();
    if (REACT_APP_FIRESTORE_EMULATOR_HOST) {
        const [host, port] = REACT_APP_FIRESTORE_EMULATOR_HOST.split(':');
        firestore.useEmulator(host, Number(port));
        console.debug(`Found REACT_APP_FIRESTORE_EMULATOR_HOST, using emulator for Firestore at ${host}:${port}`);
    }
} catch (e) {
    console.error('Error in Firebase configuration: ', e);
}

export { app, auth, firestore };
