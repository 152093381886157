import React, { Dispatch, SetStateAction, ReactElement } from 'react';
import { Modal as PolarisModal } from '@shopify/polaris';

export interface ModalConfig {
    show: boolean;
    title: ReactElement | null;
    content: ReactElement | null;
    configurationSavedRedirectUrl?: string;
    configurationSaveSuccess?: boolean;
    primaryActionLabel: string;
    secondaryActionLabel: string;
    onlyPrimaryAction: boolean;
    confirmedFormData?: boolean;
    isConfirmationModal: boolean;
}

interface ModalProps {
    modal: ModalConfig;
    setModal: Dispatch<SetStateAction<ModalConfig>>;
}

const Modal = ({ modal, setModal }: ModalProps) => {
    const {
        content,
        title,
        primaryActionLabel,
        secondaryActionLabel,
        show,
        configurationSavedRedirectUrl,
        configurationSaveSuccess,
        onlyPrimaryAction,
        isConfirmationModal,
    } = modal;

    const handleRedirect = (url: string) => {
        // Ensure break out of an iframe, if the app was accessed as embedded app inside Shopify admin
        window.parent.location.href = url;
    };

    if (isConfirmationModal)
        return (
            <div style={{}}>
                <PolarisModal
                    // activator={activator}
                    open={show}
                    onClose={() => {
                        setModal({ ...modal, show: false, confirmedFormData: false });
                    }}
                    title={title}
                    primaryAction={{
                        content: primaryActionLabel,
                        onAction: () => {
                            setModal({ ...modal, show: false, confirmedFormData: true });
                        },
                    }}
                    secondaryActions={
                        onlyPrimaryAction
                            ? undefined
                            : [
                                  {
                                      content: secondaryActionLabel,
                                      onAction: () => {
                                          setModal({ ...modal, show: false, confirmedFormData: false });
                                      },
                                  },
                              ]
                    }
                >
                    <PolarisModal.Section>{content}</PolarisModal.Section>
                </PolarisModal>
            </div>
        );

    return (
        <div style={{}}>
            <PolarisModal
                open={show}
                onClose={() => {
                    setModal({ ...modal, show: false });
                    if (onlyPrimaryAction) handleRedirect(configurationSavedRedirectUrl!);
                }}
                title={title}
                primaryAction={{
                    content: primaryActionLabel,
                    onAction: () => handleRedirect(configurationSavedRedirectUrl!),
                    disabled: !configurationSaveSuccess,
                }}
                secondaryActions={
                    onlyPrimaryAction
                        ? undefined
                        : [
                              {
                                  content: secondaryActionLabel,
                                  onAction: () => setModal({ ...modal, show: false }),
                              },
                          ]
                }
            >
                <PolarisModal.Section>{content}</PolarisModal.Section>
            </PolarisModal>
        </div>
    );
};

export default Modal;
