import React, { useState, useCallback } from 'react';
import { Modal, Button, Subheading, TextStyle } from '@shopify/polaris';
import { Payment, Refund, ItemTypes } from '../interfaces';

interface ItemModalProps {
    item: Payment | Refund;
    itemType: ItemTypes;
}

const ItemModal = ({ item, itemType }: ItemModalProps) => {
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const activator = (
        <Button plain onClick={toggleActive}>
            Details
        </Button>
    );

    let modalMarkup;
    if (itemType === ItemTypes.PAYMENT) {
        let { id, createdAt, updatedAt, paymentProcessor, shopify, status, test } = item as Payment;
        modalMarkup = (
            <>
                <Subheading>Payment Details</Subheading>
                <div className="item-detail-row-container">
                    <span>Shopify Id:</span>
                    <span>{id}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Reference:</span>
                    <span>{paymentProcessor?.transactionId || paymentProcessor?.orderId || ''}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Create Date:</span>
                    <span>{createdAt.toDate().toUTCString()}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Last Update:</span>
                    <span>{updatedAt ? updatedAt.toDate().toUTCString() : ''}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Status:</span>
                    <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Amount:</span>{' '}
                    <span>
                        {shopify.paymentRequest.amount} {shopify.paymentRequest.currency}
                    </span>
                </div>
                <div className="item-detail-row-container">
                    <span>Test Payment:</span>
                    <span>{test ? 'Yes' : 'No'}</span>
                </div>
                {shopify.refunds && (
                    <>
                        <Subheading>Refund Reference</Subheading>
                        {shopify.refunds.map((r, i) => (
                            <div key={r.refundId} className="item-detail-row-container">
                                <span>{i + 1}. Refund</span>
                                <div>
                                    <div className="item-detail-multiline-cell">
                                        <span>Refund Id:</span>
                                        <span>{r.refundId}</span>
                                    </div>
                                    <div className="item-detail-multiline-cell">
                                        <span>Amount:</span>
                                        <span>
                                            {r.amount / 100} {shopify.paymentRequest.currency}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}

                <div>
                    <Subheading>Customer Details</Subheading>
                    <div className="item-detail-row-container">
                        <span>Email:</span>
                        <span>{shopify.paymentRequest.customer.email}</span>
                    </div>
                    {shopify.paymentRequest.customer.phone_number && (
                        <div className="item-detail-row-container">
                            <span>Phone:</span>
                            <span>{shopify.paymentRequest.customer.phone_number}</span>
                        </div>
                    )}
                    <Subheading>Billing Details</Subheading>
                    <div className="item-detail-row-container">
                        <span>Name:</span>{' '}
                        <span>
                            {shopify.paymentRequest.customer.billing_address.given_name}{' '}
                            {shopify.paymentRequest.customer.billing_address.family_name}
                        </span>
                    </div>
                    <div className="item-detail-row-container">
                        <span>Address Line 1:</span>
                        <span>{shopify.paymentRequest.customer.billing_address.line1}</span>
                    </div>
                    <div className="item-detail-row-container">
                        <span>Address Line 2:</span>
                        <span>{shopify.paymentRequest.customer.billing_address.line2}</span>
                    </div>
                    <div className="item-detail-row-container">
                        <span>City:</span>
                        <span>{shopify.paymentRequest.customer.billing_address.city}</span>
                    </div>
                    <div className="item-detail-row-container">
                        <span>Postal Code:</span>
                        <span>{shopify.paymentRequest.customer.billing_address.postal_code}</span>
                    </div>
                    <div className="item-detail-row-container">
                        <span>Country:</span>
                        <span>{shopify.paymentRequest.customer.billing_address.country_code}</span>
                    </div>
                    {/* <div className="item-detail-row-container">{shopify.paymentRequest.customer.billing_address}</div> */}
                    {shopify.paymentRequest.customer.shipping_address && (
                        <div>
                            <Subheading>Shipping Details</Subheading>
                            <div className="item-detail-row-container">
                                <span>Name:</span>{' '}
                                <span>
                                    {shopify.paymentRequest.customer.shipping_address.given_name}{' '}
                                    {shopify.paymentRequest.customer.shipping_address.family_name}
                                </span>
                            </div>
                            <div className="item-detail-row-container">
                                <span>Address Line 1:</span>{' '}
                                <span>{shopify.paymentRequest.customer.shipping_address.line1}</span>
                            </div>
                            <div className="item-detail-row-container">
                                <span>Address Line 2:</span>{' '}
                                <span>{shopify.paymentRequest.customer.shipping_address.line2}</span>
                            </div>
                            <div className="item-detail-row-container">
                                <span>City:</span>
                                <span>{shopify.paymentRequest.customer.shipping_address.city}</span>
                            </div>
                            <div className="item-detail-row-container">
                                <span>Postal Code:</span>{' '}
                                <span>{shopify.paymentRequest.customer.shipping_address.postal_code}</span>
                            </div>
                            <div className="item-detail-row-container">
                                <span>Country:</span>{' '}
                                <span>{shopify.paymentRequest.customer.shipping_address.country_code}</span>
                            </div>
                            {/* <div>{shopify.paymentRequest.customer.shipping_address}</div> */}
                        </div>
                    )}
                </div>
            </>
        );
    }

    if (itemType === ItemTypes.REFUND) {
        let { id, createdAt, updatedAt, paymentProcessor, shopify, status, test } = item as Refund;
        modalMarkup = (
            <>
                <Subheading>Refund Details</Subheading>
                <div className="item-detail-row-container">
                    <span>Shopify Id:</span>
                    <span>{id}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Reference:</span>
                    <span>{paymentProcessor?.transactionId || paymentProcessor?.orderId || ''}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Create Date:</span>
                    <span>{createdAt.toDate().toUTCString()}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Last Update:</span>
                    <span>{updatedAt ? updatedAt.toDate().toUTCString() : ''}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Status:</span>
                    <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Amount:</span>{' '}
                    <span>
                        {shopify.refundRequest.amount} {shopify.refundRequest.currency}
                    </span>
                </div>
                <div className="item-detail-row-container">
                    <span>Test Refund:</span>
                    <span>{test ? 'Yes' : 'No'}</span>
                </div>

                <Subheading>Payment Reference</Subheading>
                <div className="item-detail-row-container">
                    <span>Payment Processor:</span>
                    <span>{paymentProcessor?.transactionId || paymentProcessor?.orderId || ''}</span>
                </div>
                <div className="item-detail-row-container">
                    <span>Shopify:</span>
                    <span>{shopify.paymentId}</span>
                </div>
            </>
        );
    }

    return (
        <div style={{}}>
            <Modal
                activator={activator}
                open={active}
                onClose={toggleActive}
                title={
                    <>
                        Details for {itemType.charAt(0).toUpperCase() + itemType.slice(1)}:{' '}
                        <TextStyle variation="strong">{item.id}</TextStyle>
                    </>
                }
                // primaryAction={{
                //     content: 'Import customers',
                //     onAction: toggleActive,
                // }}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: toggleActive,
                    },
                ]}
            >
                <Modal.Section>{modalMarkup}</Modal.Section>
            </Modal>
        </div>
    );
};

export default ItemModal;
