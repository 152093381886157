import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
import { app, auth, firestore } from '../utils/firebase';

import React, {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useState,
    useCallback,
} from 'react';

type FirebaseProviderType = {
    children: ReactElement;
    token: string;
};

type FirebaseContextType = {
    user: firebase.User | null;
    setUser: Dispatch<SetStateAction<firebase.User | null>>;
    initialized: boolean;
    setInitialized: Dispatch<SetStateAction<boolean>>;
};

const ctxDefaultValue: FirebaseContextType = {
    user: null,
    setUser: () => {},
    initialized: false,
    setInitialized: () => {},
};

export const FirebaseContext = createContext(ctxDefaultValue);

export const useFirebaseUser = () => {
    const { user, initialized } = useContext(FirebaseContext);
    return initialized && user;
};

const FirebaseProvider = ({ token, children }: FirebaseProviderType) => {
    const [user, setUser] = useState(ctxDefaultValue.user);
    const [initialized, setInitialized] = useState(ctxDefaultValue.initialized);

    const onAuthStateChanged = (authUser: firebase.User | null) => {
        setUser(authUser);
    };

    const initializeFirebase = useCallback(async (token: string) => {
        auth.onAuthStateChanged(onAuthStateChanged);

        await auth.signInWithCustomToken(token).catch((error) => {
            const { code } = error;
            if (code === 'auth/invalid-custom-token') {
                const message = `The provided token (${token}) is not valid. Cannot sign in to Firebase`;
                alert(message);
            }
            console.error(error);
            throw error; // re-throw to kill the execution
        });

        setInitialized(true);
    }, []);

    useEffect(() => {
        if (token) initializeFirebase(token).then(() => console.log('Firebase initialised'));
    }, [token, initializeFirebase]);

    return (
        <FirebaseContext.Provider
            value={{
                user,
                setUser,
                initialized,
                setInitialized,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;
